<template>
	<div class="" :style="scssVars">
		<div class="col column editor__content">
			<div class="ProseMirror" v-html="value"></div>
		</div>
	</div>
</template>
<script>
	export default {
		name: 'TipTapViewer',
		props: {
			value: { type: String },
			readOnly: {
				type: Boolean,
				default: false,
			},
			placeholder: {
				type: String,
				default: 'Write Something..',
			},
			extended: Boolean,
			accentColor: {
				type: String,
				default: '#000000',
			},
		},
		computed: {
			scssVars() {
				return {
					'--tip-tap-accent-color': this.accentColor,
				}
			},
		},
		created() {},
		mounted() {},
		methods: {},
	}
</script>
<style lang="scss" scoped>
	/* Basic editor styles */
	:focus {
		outline: none;
	}
	.p-editor {
		padding: 0.375rem 0.75rem;
	}

	.menu-button {
		cursor: pointer;
		margin-bottom: 0;
		margin-right: 10px;
	}
	.is-active {
		color: #3a36db;
	}
	.menu-bar {
		border-bottom: 1px solid #f3f6fd;
	}

	.editor__content::v-deep {
		padding: 0 !important;
		.ProseMirror-focused {
			outline-width: 0;
			outline: none;
		}
		.ProseMirror {
			> * + * {
				margin-top: 0.75em;
			}
			ul,
			ol {
				padding: 0 1rem;
				margin-left: 0.5rem;
			}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6 {
				line-height: 1.1;
			}
			code {
				background-color: rgba(#616161, 0.1);
				color: #616161;
			}
			pre {
				background: #0d0d0d;
				color: #fff;
				font-family: monospace;
				padding: 0.75rem 1rem;
				border-radius: 0.5rem;
				code {
					color: inherit;
					padding: 0;
					background: none;
					font-size: 0.8rem;
				}
			}
			img {
				max-width: 100%;
				height: auto;
			}
			blockquote {
				padding-left: 1rem;
				border-left: 2px solid rgba(#0d0d0d, 0.1);
			}
			hr {
				border: none;
				border-top: 2px solid rgba(#0d0d0d, 0.1);
				margin: 2rem 0;
			}
			ul,
			li {
				padding: revert !important;
			}
			::marker {
				color: var(--tip-tap-accent-color);
			}
		}

		/* Placeholder (on every new line) */
		.ProseMirror p.is-editor-empty:first-child::before {
			content: attr(data-placeholder);
			float: left;
			color: #ced4da;
			pointer-events: none;
			height: 0;
		}

		.ProseMirror {
			overflow-y: unset !important;
			height: auto !important;
		}

		.flexible {
			.ProseMirror {
				height: auto;
				min-height: 100px;
			}
		}
		[data-youtube-video] {
			max-width: 600px;
			width: 100%;
			aspect-ratio: 1.5;
		}
	}
</style>
